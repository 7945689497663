import { AxiosResponse } from "axios";

import api from "~/utils/api/api";
import { ICreateUserWoPasswordPayload, ICreateUserWoPasswordResponse } from "~/utils/interfaces/User";

const baseUrl = "users";

/* Changing structure to something more web-app alike */
class UserService {
  fetchRoles = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${baseUrl}/${id}/list_roles/`);
  };

  toggleSuperadmin = async (id: string, data: { action: boolean }): Promise<AxiosResponse<any>> => {
    return api.patch(`${baseUrl}/${id}/toggle_superadmin/`, data);
  };

  fetchUserAppointments = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${baseUrl}/${id}/appointments/`);
  };

  createUserWoPassword = async (
    data: ICreateUserWoPasswordPayload,
    salesSource: string,
  ): Promise<AxiosResponse<ICreateUserWoPasswordResponse>> => {
    return api.post(`users/register_wo_password/`, data, { params: { source: salesSource } });
  };
}

const userService = new UserService();

export default userService;
